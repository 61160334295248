<template>
  <div>
    <WidgetsDropdown :invoices="invoices" :invoicesAmount="invoicesAmount" :invoicesAverage="invoicesAverage" :payments="payments"/>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Facturación vs Cobranza</h4>
            <div class="small text-muted">{{graph.title}}</div>
          </CCol>
          <CCol sm="7" class="d-none d-md-block">
            <!--
            <CButtonGroup class="float-right mr-3">
              <CButton
                color="outline-secondary"
                v-for="(value, key) in ['Semana', 'Mes', 'Año']"
                :key="key"
                class="mx-0"
                :pressed="value === selected ? true : false"
                @click="selected = value"
              >
                {{value}}
              </CButton>
            </CButtonGroup>
            -->
          </CCol>
        </CRow>
        <DashboardChart :graph="graph" @totals="totals" style="height:300px;margin-top:40px;"/>
      </CCardBody>
      <CCardFooter>
        <CRow class="text-center">
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Total Facturado</div>
            <strong>$ {{totalInvoices | currency}}</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="success"
              :value="40"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Total Pagado</div>
            <strong>$ {{totalPayments | currency}}</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="info"
              :value="20"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Promedio Facturado</div>
            <strong>$ {{averageInvoices | currency}}</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="warning"
              :value="60"
            />
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>
<script>
import DashboardChart from './charts/DashboardChart'
import WidgetsDropdown from './widgets/WidgetsDropdown'
import WidgetsBrand from './widgets/WidgetsBrand'

import dashboard from '../services/dashboard';
import store from '../store'

export default {
  name: 'Dashboard',
  components: {
    DashboardChart,
    WidgetsDropdown,
    WidgetsBrand
  },
  data () {
    return {
      selected: 'Mes',
      tableItems: [
        {
          avatar: { url: 'img/avatars/1.jpg', status: 'success' },
          user: { name: 'Yiorgos Avraamu', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'USA', flag: 'cif-us' },
          usage: { value: 50, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Mastercard', icon: 'cib-cc-mastercard' },
          activity: '10 sec ago'
        },
        {
          avatar: { url: 'img/avatars/2.jpg', status: 'danger' },
          user: { name: 'Avram Tarasios', new: false, registered: 'Jan 1, 2015' },
          country: { name: 'Brazil', flag: 'cif-br' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Visa', icon: 'cib-cc-visa' },
          activity: '5 minutes ago'
        },
        {
          avatar: { url: 'img/avatars/3.jpg', status: 'warning' },
          user: { name: 'Quintin Ed', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'India', flag: 'cif-in' },
          usage: { value: 74, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Stripe', icon: 'cib-stripe' },
          activity: '1 hour ago'
        },
        {
          avatar: { url: 'img/avatars/4.jpg', status: '' },
          user: { name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'France', flag: 'cif-fr' },
          usage: { value: 98, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'PayPal', icon: 'cib-paypal' },
          activity: 'Last month'
        },
        {
          avatar: { url: 'img/avatars/5.jpg', status: 'success' },
          user: { name: 'Agapetus Tadeáš', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Spain', flag: 'cif-es' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Google Wallet', icon: 'cib-google-pay' },
          activity: 'Last week'
        },
        {
          avatar: { url: 'img/avatars/6.jpg', status: 'danger' },
          user: { name: 'Friderik Dávid', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Poland', flag: 'cif-pl' },
          usage: { value: 43, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Amex', icon: 'cib-cc-amex' },
          activity: 'Last week'
        }
      ],
      tableFields: [
        { key: 'avatar', label: '', _classes: 'text-center' },
        { key: 'user' },
        { key: 'country', _classes: 'text-center' },
        { key: 'usage' },
        { key: 'payment', label: 'Payment method', _classes: 'text-center' },
        { key: 'activity' },
      ],
      graph: {
        title: '',
        totals: [],
        ranges: []
      },
      invoices: "0",
      invoicesAmount: "$ 0.00",
      invoicesAverage: "$ 0.00",
      payments: "$ 0.00",
      countInvoices: "0",
      totalInvoices: "$ 0.00",
      totalPayments: "$ 0.00",
      averageInvoices: "$ 0.00"
    }
  },
  mounted: async function() {
    let response = await dashboard.getGraph();

    let data = response.data;

    this.graph.title = data.ranges[0].name+" - "+data.ranges[data.ranges.length - 1].name;

    this.graph.totals = data.totals;
    this.graph.ranges = data.ranges;

    let response2 = await dashboard.getInvoices(); 

    this.invoices = response2.data+"";

    let response3 = await dashboard.getAmountInvoices(); 

    this.invoicesAmount = "$ "+response3.data;

    let response4 = await dashboard.getAverageInvoices(); 

    this.invoicesAverage = "$ "+response4.data;

    let response5 = await dashboard.getPayments(); 

    this.payments = "$ "+response5.data;
  },
  methods: {
    async totals (totals){
      this.countInvoices = totals[0];
      this.totalInvoices = totals[1];
      this.averageInvoices = totals[2];
      this.totalPayments = totals[3];
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
</script>
