<template>
  <CRow>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="gradient-primary" :header="invoices" text="Facturas del Mes" style="background: linear-gradient(45deg, #0070cd 0%, #5491d3 100%) !important;">
        <template #default>
          <CIcon name="cil-file"/>
        </template>
        <template #footer><br></template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="gradient-info" :header="invoicesAmount" text="Monto de Facturación del Mes" style="background: linear-gradient(45deg, #5491d3 0%, #4dbfb4 100%) !important;">
        <template #default>
          <CIcon name="cil-dollar"/>
        </template>
        <template #footer><br></template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown
        color="gradient-warning"
        :header="invoicesAverage"
        text="Monto Promedio del Mes"
      >
        <template #default>
          <CIcon name="cil-money"/>
        </template>
        <template #footer><br></template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown
        color="gradient-danger"
        :header="payments"
        text="Pagos Recibidos del Mes"
      >
        <template #default>
          <CIcon name="cil-wallet"/>
        </template>
        <template #footer><br></template>
      </CWidgetDropdown>
    </CCol>
  </CRow>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'

export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  props: {
    invoices: "",
    invoicesAmount: "",
    invoicesAverage: "",
    payments: ""
  },
}
</script>
